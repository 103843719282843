import React from "react"
import v4 from "uuid"

export const renderBlocks = (blocks, renderCallback, columnCount, columnClassNames, rowClassNames) => {
  let rows = []
  blocks.map((block, index) => {
    const rowIndex = Math.floor(index / columnCount)
    let columns = rows[rowIndex] ? rows[rowIndex] : []
    columns.push(renderCallback(block))
    rows[rowIndex] = columns
    return rows[rowIndex]
  })
  return rows.map((row) =>
    <div key={v4()} className={rowClassNames ? rowClassNames + " row" : "row"}>
      {row.map(column => <div key={v4()} className={columnClassNames}>{column}</div>)}
    </div>,
  )
}