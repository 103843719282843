import React from "react"
import Hero from "../components/hero"
import Dotdotdot from "react-dotdotdot"
import Header from "../components/header"
import Footer from "../components/footer"
import { renderBlocks } from "../components/misc"
import { BIOS } from "../components/bios"
import styles from "./index.module.css"


const BioFullInfo = (props) => {
  const { name, info, title, onBackClick, noBreak } = props
  return (
    <section className="image-block short-pad">
      <div className="container">
        <div className="row">
          <div className="col-md-10 col-sm-8 ">
            <div className="text-block">
              <div className="detail-line"/>
              <h4>{name}</h4>
              <div className="member-spotlight">
                <div className="title "
                     style={{ fontWeight: 900, fontSize: "2rem", marginBottom: "2rem" }}>{title}</div>
              </div>
              <div className={!noBreak? styles.bigInfo : null}>
                {info}
              </div>
              <a href="#" onClick={onBackClick}>
                <i
                  style={{
                    fontSize: "4rem",
                    margin: "1rem 0",
                  }}
                  className="fas fa-long-arrow-alt-left"
                />

              </a>
            </div>
          </div>

          <div className="col-md-2 col-sm-4">
            <div className="member-spotlight">
              {/*<img alt="Team Member" src="img/team1.jpg"/>*/}

            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

class AboutPage extends React.Component {

  state = {
    bio: null,
  }

  onBioChange = (bio) => {
    this.setState({ bio })
  }

  onBioClose = (e) => {
    e.preventDefault()
    this.setState({
      bio: null,
    })
  }

  renderBioShort = (bio) => {
    const { name, info, title } = bio
    return (
      <div className={"team-member " + styles.team}>
        <h3 style={{ height: 80, display: "flex", alignItems: "flex-end" }}>{name}</h3>
        <span style={{ height: 20 }}>{title}</span>
        <Dotdotdot className={styles.team__info} clamp={3}>
          {info}
        </Dotdotdot>
        <a href="#"
           style={{ marginTop: "1rem" }}
           onClick={(e) => {
             e.preventDefault()
             this.onBioChange(bio)
           }}>
          Διαβάστε περισσότερα
        </a>
      </div>
    )
  }


  render() {
    const { bio } = this.state
    return (
      <div>
        <Header/>
        <Hero small>&nbsp;</Hero>
        <section className="team-2">
          <div className="container">


            <div className="text-block">
              <h4 style={{ fontWeight: 900 }}>Η ομάδα</h4>
            </div>
            <hr/>
            {bio ? <BioFullInfo
              {...bio}
              onBackClick={this.onBioClose}
            /> : renderBlocks(BIOS, this.renderBioShort, 3, `col-md-4 col-sm-6 ${styles.teamCol}`, "align-items-stretch")}

          </div>
        </section>
        <Footer/>
      </div>
    )
  }
}

export default AboutPage